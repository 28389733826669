import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Post from "../components/post/post"

const CategoryArchive = ({ data, location }) => {
  const category = data.wpCategory;
  const posts = data.wpCategory.posts.nodes;
  return (
    <Layout>
      <Seo
        title={category.name}
        description={''}
        pageSeo={category}
        location={location}
      />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center">{category.name}</h1>
          </div>
          {posts.length > 0 &&
            posts.map(post => {
              return <div className="col-12 col-md-6 col-xl-4" key={post.uri}>
                <Post post={post} />
              </div>
            })
          }
          {!posts.length &&
            <div className="col-12">
              <p>
                No posts found. Add posts to your WordPress site and they'll
                appear here!
              </p>
            </div>
          }
        </div>
      </div>
    </Layout>
  )
}

export default CategoryArchive

export const pageQuery = graphql`
  query WordPressCategoryPosts(
    $id: String!
  ) {
    wpCategory(id: {eq: $id}) {
      id
      name
      description
      posts {
        nodes {
          excerpt
          uri
          date(formatString: "MMMM DD, YYYY")
          title
          excerpt
          featuredImage {
            node {
              gatsbyImage(width: 600,quality: 80)
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
  }
`
